import * as React from 'react';
import TopBar from '../components/topbar/topbar';
import { paragraph, title } from './support.module.scss';

const SupportPage = () => {
  return (
    <div className="pageWithTopBar">
      <div className="topbar">
        <TopBar showSettings={false} />
      </div>

      <div className="page">
        <div className={title}>Support </div>
        <p className={paragraph}>
          This webpage was developed out of my free-time and is maintained out of my own pocket. Any contributions to
          help cover the development or maintainence costs are appreciated. You can help support by using the "Buy Me a
          Coffee" button.
        </p>
        <div className={title}>About </div>
        <p className={paragraph}>
          The page is based off of the book "500 Korean Verbs". I have hand typed the dictionary form of the verbs and
          generate the conjugations procedurally. I am not a Korean expert and cannot guarentee the accuracy of the
          conjugations. As I learn more I hope to add other grammar elements to the site.
        </p>
        <div className={title}>Errors</div>
        <p className={paragraph}>
          If you notice a mistake in any of the conjugations or a typo send the details to{' '}
          <a href="mailto:koreanpracticedotnet@gmail.com">koreanpracticedotnet@gmail</a>
        </p>
      </div>
    </div>
  );
};

export default SupportPage;
