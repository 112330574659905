import { Button, ButtonGroup } from '@mui/material';
import * as React from 'react';
import { ConjugationTypes, Formalities, Tenses } from '../../data/conjugation';
import SettingsContext from './settings.context';
import { conjugation, disabled, formality, formElement, label, settingsContainer, tense } from './settings.module.scss';

const updateListItem = (list: string[], value: string): string[] => {
  const found = list.find((i) => i === value);
  if (found) return list.filter((i) => i !== value);
  else return [...list, value];
};

const Settings = () => {
  const { settings, settingsUpdater } = React.useContext(SettingsContext);

  const pastActive = settings.tenses.find((t) => t === Tenses.Past.code);
  const presentActive = settings.tenses.find((t) => t === Tenses.Present.code);
  const futureActive = settings.tenses.find(
    (t) => t === Tenses.Future.code || t === Tenses.FuturePresumptive.code || t === Tenses.FutureProbable.code
  );

  const informalActive = settings.formalities.find((f) => f === Formalities.Informal.code);
  const politeActive = settings.formalities.find((f) => f === Formalities.Polite.code);
  const neutralActive = settings.formalities.find((f) => f === Formalities.Neutral.code);
  const deferrentialActive = settings.formalities.find((f) => f === Formalities.Deferrential.code);

  const declaractiveActive = settings.conjugations.find((c) => c === ConjugationTypes.Declarative.code);
  const interrogativeActive = settings.conjugations.find((c) => c === ConjugationTypes.Interrogative.code);
  const propositiveActive = settings.conjugations.find((c) => c === ConjugationTypes.Propositive.code);
  const nominalActive = settings.conjugations.find((c) => c === ConjugationTypes.Nominal.code);
  const adnominalActive = settings.conjugations.find((c) => c === ConjugationTypes.Adnominal.code);
  const andActive = settings.conjugations.find((c) => c === ConjugationTypes.And.code);
  const orActive = settings.conjugations.find((c) => c === ConjugationTypes.Or.code);
  const butActive = settings.conjugations.find((c) => c === ConjugationTypes.But.code);
  const soActive = settings.conjugations.find((c) => c === ConjugationTypes.So.code);
  const inOrderToActive = settings.conjugations.find((c) => c === ConjugationTypes.InOrderTo.code);
  const ifActive = settings.conjugations.find((c) => c === ConjugationTypes.If.code);
  const notActive = settings.conjugations.find((c) => c === ConjugationTypes.Not.code);

  const dictionaryActive = settings.showDictionaryForm;
  const englishActive = settings.showEnglish;

  return (
    <div className={settingsContainer}>
      <div className={formElement}>
        <div className={label}>Show dictionary form?</div>
        <ButtonGroup
          className="buttonGroup"
          id="dictionary-setting"
          variant="contained"
          aria-label="Show dictionary form?"
        >
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, showDictionaryForm: true });
            }}
            className={`${dictionaryActive ? '' : disabled}`}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, showDictionaryForm: false });
            }}
            className={`${!dictionaryActive ? '' : disabled}`}
          >
            No
          </Button>
        </ButtonGroup>
      </div>
      <div className={formElement}>
        <div className={label}>Show english?</div>
        <ButtonGroup className="buttonGroup" id="english-setting" variant="contained" aria-label="Show english?">
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, showEnglish: true });
            }}
            className={`${englishActive ? '' : disabled}`}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, showEnglish: false });
            }}
            className={`${!englishActive ? '' : disabled}`}
          >
            No
          </Button>
        </ButtonGroup>
      </div>
      <div className={formElement}>
        <div className={label}>Conjugations to include</div>
        <ButtonGroup
          className="buttonGroup"
          id="conjugation-setting"
          variant="contained"
          aria-label="Included conjugations"
        >
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Declarative.code),
              });
            }}
            className={`${conjugation} ${declaractiveActive ? '' : disabled}`}
          >
            {ConjugationTypes.Declarative.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Interrogative.code),
              });
            }}
            className={`${conjugation} ${interrogativeActive ? '' : disabled}`}
          >
            {ConjugationTypes.Interrogative.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Propositive.code),
              });
            }}
            className={`${conjugation} ${propositiveActive ? '' : disabled}`}
          >
            {ConjugationTypes.Propositive.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Nominal.code),
              });
            }}
            className={`${conjugation} ${nominalActive ? '' : disabled}`}
          >
            {ConjugationTypes.Nominal.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Adnominal.code),
              });
            }}
            className={`${conjugation} ${adnominalActive ? '' : disabled}`}
          >
            {ConjugationTypes.Adnominal.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.And.code),
              });
            }}
            className={`${conjugation} ${andActive ? '' : disabled}`}
          >
            {ConjugationTypes.And.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Or.code),
              });
            }}
            className={`${conjugation} ${orActive ? '' : disabled}`}
          >
            {ConjugationTypes.Or.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.But.code),
              });
            }}
            className={`${conjugation} ${butActive ? '' : disabled}`}
          >
            {ConjugationTypes.But.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.So.code),
              });
            }}
            className={`${conjugation} ${soActive ? '' : disabled}`}
          >
            {ConjugationTypes.So.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.InOrderTo.code),
              });
            }}
            className={`${conjugation} ${inOrderToActive ? '' : disabled}`}
          >
            {ConjugationTypes.InOrderTo.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.If.code),
              });
            }}
            className={`${conjugation} ${ifActive ? '' : disabled}`}
          >
            {ConjugationTypes.If.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                conjugations: updateListItem(settings.conjugations, ConjugationTypes.Not.code),
              });
            }}
            className={`${conjugation} ${notActive ? '' : disabled}`}
          >
            {ConjugationTypes.Not.label}
          </Button>
        </ButtonGroup>
      </div>
      <div className={formElement}>
        <div className={label}>Tenses to include</div>
        <ButtonGroup className="buttonGroup" id="tense-setting" variant="contained" aria-label="Included tenses">
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, tenses: updateListItem(settings.tenses, Tenses.Past.code) });
            }}
            className={`${tense} ${pastActive ? '' : disabled}`}
          >
            {Tenses.Past.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, tenses: updateListItem(settings.tenses, Tenses.Present.code) });
            }}
            className={`${tense} ${presentActive ? '' : disabled}`}
          >
            {Tenses.Present.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({ ...settings, tenses: updateListItem(settings.tenses, Tenses.Future.code) });
            }}
            className={`${tense} ${futureActive ? '' : disabled}`}
          >
            {Tenses.Future.label}
          </Button>
        </ButtonGroup>
      </div>
      <div className={formElement}>
        <div className={label}>Formalities to include</div>
        <ButtonGroup
          className="buttonGroup"
          id="formalities-setting"
          variant="contained"
          aria-label="Included formalities"
        >
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                formalities: updateListItem(settings.formalities, Formalities.Informal.code),
              });
            }}
            className={`${formality} ${informalActive ? '' : disabled}`}
          >
            {Formalities.Informal.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                formalities: updateListItem(settings.formalities, Formalities.Polite.code),
              });
            }}
            className={`${formality} ${politeActive ? '' : disabled}`}
          >
            {Formalities.Polite.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                formalities: updateListItem(settings.formalities, Formalities.Neutral.code),
              });
            }}
            className={`${formality} ${neutralActive ? '' : disabled}`}
          >
            {Formalities.Neutral.label}
          </Button>
          <Button
            onClick={() => {
              settingsUpdater({
                ...settings,
                formalities: updateListItem(settings.formalities, Formalities.Deferrential.code),
              });
            }}
            className={`${formality} ${deferrentialActive ? '' : disabled}`}
          >
            {Formalities.Deferrential.label}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Settings;
