import { Button } from '@mui/material';
import { Link } from 'gatsby';
import * as React from 'react';
import Settings from '../settings/settings';
import { buttons, topbar } from './topbar.module.scss';

const TopBar = (props) => {
  const [showSettings, showSettingsUpdater] = React.useState(false);

  return (
    <div className={topbar}>
      <div className={buttons}>
        <Button>
          <Link to="/Reference/애쓰다/">Reference</Link>
        </Button>
        <Button onClick={() => showSettingsUpdater(false)}>
          <Link to="/">Training</Link>
        </Button>
        {props.showSettings === true ? (
          <Button onClick={() => showSettingsUpdater(!showSettings)}>Settings</Button>
        ) : null}
        <Button>
          <Link to="/support">About</Link>
        </Button>
        <a href="https://ko-fi.com/L3L6FMTZV" target="_blank">
          <img
            height="36"
            style={{ border: '0px', height: '36px' }}
            src="https://cdn.ko-fi.com/cdn/kofi2.png?v=3"
            alt="Buy Me a Coffee at ko-fi.com"
          />
        </a>
      </div>
      {showSettings ? (
        <div>
          <Settings></Settings>
        </div>
      ) : null}
    </div>
  );
};

export default TopBar;
