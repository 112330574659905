import * as React from 'react';
import { ConjugationTypes, Formalities, Tenses } from '../../data/conjugation';

export interface Settings {
  showDictionaryForm: boolean;
  showEnglish: boolean;
  mode: string | 'type' | 'match';
  conjugations: string[];
  tenses: string[];
  formalities: string[];
}

const SettingsContext = React.createContext({
  settings: {
    showEnglish: true,
    showDictionaryForm: true,
    mode: 'type',
    conjugations: [ConjugationTypes.Declarative.code],
    tenses: [Tenses.Past.code, Tenses.Present.code, Tenses.Future.code],
    formalities: [
      Formalities.Informal.code,
      Formalities.Polite.code,
      Formalities.Neutral.code,
      Formalities.Deferrential.code,
    ],
  },
  settingsUpdater: (settings: Settings): any => {},
});

export const SettingsProvider = SettingsContext.Provider;
export default SettingsContext;
